<template>
  <div id="app" class="container">
    <!-- 头部导航 -->
    <div class="header">
      <header-nav></header-nav>
    </div>

    <!-- 内容部分 -->
    <div class="content">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </div>

    <div class="line" style="height: 30px;"></div>

    <!-- 底部导航 -->
    <div class="footer">
      <footerNav></footerNav>
    </div>

  </div>
</template>

<script>
import headerNav from "@/components/headerNav/index.vue"
import footerNav from "@/components/footerNav/index.vue"
export default {
  name: 'HomeView',
  components: {
    headerNav,
    footerNav
  },
  data(){
    return{}
  },
}
</script>

<style lang="scss">
*{margin: 0; padding: 0;}
  .container{
    width: 100% !important;
    padding: 0;
    .header{
      width: 100%;
      background: #3a4c67;
    }
    .content{
      padding-top: 1em;
      min-height: 80vh;
    }
    .footer{
      width: 100%;
      background-color: #f6faff;
    }
  }
</style>

