import request from '../utils/request'


 // 帮助文档
function help_list(params) {
    return request({
        url: '/api/document/help',
        method: 'get',
        params
    })
}

// 文档详情
function help_info(params) {
    return request({
        url: '/api/document/info',
        method: 'get',
        params
    })
}

// 更新日志
function update_version(params) {
    return request({
        url: '/api/document/version',
        method: 'get',
        params
    })
}


export default {
    help_list,
    help_info,
    update_version
}