/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import Vue from 'vue'
import axios from 'axios'
import router from '../src/router/index'
import store from '../src/store'
import { message } from 'element-ui'
/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */

const tip = msg => {
    message({
        message: msg,
        type: 'warning'
    });
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
        tip('登录失效，自动登录中');
        localStorage.removeItem('token')
    /**
     * 请求失败后的错误统一处理
     * @param {Number} status 请求失败的状态码
     * @param other
     * @param code
     */
}
const errorHandle = (status, other, code) => {
    // 状态码判断
    switch (status) {
        // 401: 未登录状态，跳转登录页
        case 401:
            toLogin();
            break;
            // 403 token过期
            // 清除token并跳转登录页
        case 403:
            toLogin();
            break;
            // 404请求不存在
        case 404:
            tip('请求的资源不存在');
            break;
        default:
            console.log(other);
    }
}

// 创建axios实例
const timestamp = Date.parse(new Date());

let instance = axios.create({
    baseURL: "https://crm-api.zhongshengrunde.com",
    timeout: 1000 * 12
});

// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded,';


/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
    config => {
        config.headers['XX-Api-Version'] = '1.0';
        config.headers['XX-App-Version'] = '0.1';
        config.headers['XX-From-Type'] = 'pc';
        config.headers['XX-Token'] = localStorage.getItem('token');

        config.data = config.data || {};
        config.params = config.params || {};
        return config;
    },
    error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    res => {
        if (res.status === 200 && res.data.code == 200) {
            return Promise.resolve(res.data)
        } else if (res.status === 200 && res.data.code === 204) {
            tip(res.data.msg);
        } else if (res.status === 200 && res.data.code === 500) {
            tip('系统错误');
            return Promise.reject(res)
        } else if (res.status === 200 && res.data.code === 10001) {
            toLogin();
        }
    },
    // 请求失败
    error => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data.message, );
            return Promise.reject(response);
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            if (!window.navigator.onLine) {
                // store.commit('changeNetwork', false);
            } else {
                return Promise.reject(error);
            }
        }
    });
export default instance
