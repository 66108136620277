<template>
    <div class="footer-inner">
        <div class="text">京公网安备
        11010802035166
        <span>
          <a target="blank" href="https://beian.miit.gov.cn/">中盛润德营业执照</a> 
           <a target="blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802035166">中盛润德ICP许可证</a></span>
      </div>
      <div class="text">版权所有：北京中盛润德教育科技有限公司｜地址：北京市海淀区成府路45号中关村智造大街G座二层J334</div>
    </div>
  </template>
  
  <script>  
  export default {
    data(){
      return{
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .footer-inner {
    width: 1250px;      
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
      .text {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.6;
        color: #8c8c8c !important;

        a {
          color: #4E80FF;
          margin-right: 8px;
          text-decoration: none;
        }
      }

      img {
        margin-top: 10px;
      }
    }
  </style>