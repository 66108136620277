import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    HELP_INFO:'',

  },
  getters: {
  },
  mutations: {
    SET_HELP_INFO(STATE,DATA){
      STATE.HELP_INFO = DATA
    }
  },
  actions: {
  },
  modules: {
  }
})
