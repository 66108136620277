<template>
  <div class="header-inner">
    <!-- 公司logo && 名称 -->
    <div class="head-company">
      <div class="company-logo">
        <!-- <img src="@/assets/logo.png" alt="" srcset="" /> -->
      </div>
      <div class="company-name">中盛润德CRM学习中心</div>
    </div>

    <!-- 菜单导航 -->
    <div class="head-menus">
        <div :class="$route.path==item.path?'active menu-item': 'menu-item'" v-for="(item,index) in menusList" :key="index">
            <span @click="handleToPath(item)">{{ item.name }}</span>
        </div>
    </div>
  </div>
</template>

<script>  
export default {
  data(){
    return{
      menusList:[
        // {name:'首页',id:1,path:''},
        {name:'CRM帮助文档',id:2,path:'/'},
        // {name:'CRM视频教程',id:3,path:''},
        // {name:'精品课程',id:3,path:''},
        // {name:'常见问题',id:4,path:''},
        {name:'更新日志',id:5,path:'/update_log'}
      ]
    }
  },
  methods:{
    handleToPath(item){
      let path = item.path
      if (this.$route.path == path || !path) return
      this.$router.push(path)
    }
  }
};
</script>

<style lang="scss" scoped>
.header-inner {
  width: 1250px;      
  min-height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .head-company {
    display: flex;
    align-items: center;
    .company-logo {
      width: 32px;
      height: 32px;
      margin-right: 10px;
      img {
        width: 100%;      
        height: 100%;
      }
    }
    .company-name {
      font-size: 18px;
      color: #fff;
    }
  }
  .head-menus{
    display: flex;
    align-items: center;
    .menu-item{
      color: #ffffff;;
      font-size: 14px;
      padding: 10px 15px;
      cursor: pointer;
      transition: .4s all;
    }

    .active{
      color: #409EFF;
    }
  }
}
</style>